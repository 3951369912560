
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import TeamResult from "../../models/sportevent/TeamResult";
import TeamCompetition from "../../models/sportevent/TeamCompetition";
import SporteventCompetition from "../../models/sportevent/SporteventCompetition";
import ListResultsTeamTableRowComponent from "./ListResultsTeamTableRow.vue";
import SporteventCompetitionService from "../../services/sportevent/SporteventCompetitionService";
import PrintSettingsService from "../../services/helper/PrintSettingsService";

@Component({
  components: {
    ListResultsTeamTableRowComponent,
  },
})
export default class ListResultsTeamTableComponent extends Vue {
  public loading = true;
  public hide = true;

  @Prop({ required: true })
  public teamCompetition!: TeamCompetition;

  @Prop({ required: true })
  public allTeamResults!: TeamResult[];

  @Prop({ required: true })
  public allSporteventCompetition!: SporteventCompetition[];

  @Prop({ default: true })
  public sortRankReverse!: boolean;

  public currentTeamResults!: TeamResult[];
  public sporteventCompetition!: SporteventCompetition;

  @Watch("sortRankReverse")
  onPropertyChanged(sortReverse: string) {
    console.log("sortRankReverse", sortReverse);
    this.loading = true;
    if (sortReverse) {
      this.currentTeamResults.sort((a, b) => {
        if (a.totalPoints < b.totalPoints) return -1;
        if (a.totalPoints > b.totalPoints) return 1;
        return 0;
      });
    } else {
      this.currentTeamResults.sort((a, b) => {
        if (a.totalPoints < b.totalPoints) return 1;
        if (a.totalPoints > b.totalPoints) return -1;
        return 0;
      });
    }
    this.loading = false;
  }

  async mounted(): Promise<void> {
    this.loading = true;
    this.currentTeamResults = this.allTeamResults.filter( (teamresult: TeamResult) => teamresult.team.teamCompetition.id === this.teamCompetition.id);

    if (this.currentTeamResults.length > 0) {
      this.hide = false;
      if (this.sporteventCompetition === undefined) {
        const sc = this.allSporteventCompetition.find(
          (sc) =>
            sc.id ===
            this.currentTeamResults[0].team.teamCompetition
              .sporteventCompetition.id
        );
        if (sc !== undefined && sc.id !== undefined) {
          this.sporteventCompetition = await SporteventCompetitionService.get(
            sc.id
          );
        }
      }
    }
    let rank = 1;
    this.currentTeamResults.forEach((teamResult: TeamResult) => {
      teamResult.rank = rank;
      rank++;
    });
    PrintSettingsService.setHeader();
    this.loading = false;
  }
}
