
import { Component, Vue } from "vue-property-decorator";

import formatDate from "../../plugins/formatDate";
import SporteventCompetition from "../../models/sportevent/SporteventCompetition";
import TeamCompetition from "../../models/sportevent/TeamCompetition";
import TeamCompetitionService from "../../services/sportevent/TeamCompetitionService";
import TeamService from "../../services/sportevent/TeamService";
import Team from "../../models/sportevent/Team";
import TeamResult from "../../models/sportevent/TeamResult";
import ListResultsTeamTableComponent from "./ListResultsTeamTable.vue";
import StarterService from "../../services/sportevent/StarterService";
import SporteventService from "../../services/sportevent/SporteventService";

@Component({
  components: {
    ListResultsTeamTableComponent,
  },
  filters: {
    formatDate,
  },
})
export default class ListResultsTeamComponent extends Vue {
  public loading = true;

  public allSporteventCompetition: SporteventCompetition[] = [];
  public allTeams: Team[] = [];
  public allTeamCompetitions: TeamCompetition[] = [];
  public allTeamResults: TeamResult[] = [];

  public sortOptions = [
    { item: "A", name: "Nach Altersklassen" },
    { item: "B", name: "Nach Altersklassen (Ältere zuerst)" },
    //{ item: "C", name: "Nach Durchgang & Altersklassen (Ältere zuerst)" },
    //{ item: "D", name: "Nach Durchgang & Altersklassen (Ältere zuerst)" },
  ];

  public selectedSortOption = "A";

  public sortOptionsRank = [
    { item: false, name: "Ersten Rang zuerst" },
    { item: true, name: "Letzten Rang zuerst" },
  ];

  public selectedSortOptionRank = false;

  async mounted(): Promise<void> {
    const sportevent = SporteventService.get();
    if (sportevent.id != undefined) {
      this.loading = true;

      const allTeamCompetitions =
        await TeamCompetitionService.getAllBySportevent(sportevent.id);

      if (allTeamCompetitions !== undefined)
        this.allTeamCompetitions = allTeamCompetitions;
      const allTeamResults = await TeamService.getAllTeamsWithResults(
        sportevent.id
      );
      if (allTeamResults !== undefined) this.allTeamResults = allTeamResults;

      this.allSporteventCompetition =
        await StarterService.getAllSporteventCompetitions();
    }
    this.sortbyAge();
    this.loading = false;
  }

  updateSort(): void {
    this.loading = true;
    console.log("update sort");
    if (this.selectedSortOption === "A") {
      this.sortbyAge();
    } else if (this.selectedSortOption === "B") {
      this.sortbyAge(true);
    } else if (this.selectedSortOption === "C") {
      //this.sortbyStartgroupHideStarttime();
    } else if (this.selectedSortOption === "D") {
      //this.sortbyStartgroupHideStarttime();
    }
    this.loading = false;
  }

  sortbyAge(agereverse = false): void {
    if (this.allTeamCompetitions !== undefined) {
      this.allTeamCompetitions.sort((a, b) => {
        if (
          a.sporteventCompetition.competition.name.substring(0, 2) <
          b.sporteventCompetition.competition.name.substring(0, 2)
        )
          return -1;
        if (
          a.sporteventCompetition.competition.name.substring(0, 2) >
          b.sporteventCompetition.competition.name.substring(0, 2)
        )
          return 1;
        if (
          a.sporteventCompetition.competition.ageGenderGroup.ageTo <
          b.sporteventCompetition.competition.ageGenderGroup.ageTo
        )
          return agereverse ? 1 : -1;
        if (
          a.sporteventCompetition.competition.ageGenderGroup.ageTo >
          b.sporteventCompetition.competition.ageGenderGroup.ageTo
        )
          return agereverse ? -1 : 1;
        if (
          a.sporteventCompetition.competition.ageGenderGroup.gender <
          b.sporteventCompetition.competition.ageGenderGroup.gender
        )
          return -1;
        if (
          a.sporteventCompetition.competition.ageGenderGroup.gender >
          b.sporteventCompetition.competition.ageGenderGroup.gender
        )
          return 1;
        return 0;
      });
    }
  }

  OverallWinners(): void {
    let test: any[] = [];

    this.allTeamCompetitions;

    this.allTeamResults;
  }
}
