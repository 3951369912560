var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.loading)?_c('table',{staticClass:"noborder"},[_c('thead',[_c('tr',{staticClass:"teamheader"},[_c('th',{staticClass:"textleft teamname"},[(_vm.team.name != '')?_c('span',[_vm._v(" "+_vm._s(_vm.team.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.team.society.shortname)+" ")])]),_vm._l((_vm.disciplines),function(discipline){return _c('th',{key:discipline.id,staticClass:"disciplinecol"},[_vm._v(" "+_vm._s(_vm.getDisciplineSum(discipline.id))+" ")])})],2)]),_c('tbody',{staticClass:"team-result-table"},_vm._l((_vm.team.starters),function(starter){return _c('tr',{key:starter.id},[_c('td',{staticClass:"textleft starters"},[_vm._v(" "+_vm._s(starter.person.firstname)+" "+_vm._s(starter.person.lastname)+" ")]),_vm._l((_vm.disciplines),function(discipline){return _c('td',{key:discipline.id,staticClass:"disciplinecol"},_vm._l((_vm.teamResultDisciplines),function(teamResultDiscipline){return _c('span',{key:teamResultDiscipline.id},[(teamResultDiscipline.discipline.id == discipline.id)?_c('span',[_vm._l((teamResultDiscipline.usedDisciplineResults),function(usedDisciplineResult){return _c('span',{key:usedDisciplineResult.id},[(
                  usedDisciplineResult.starter.id == starter.id &&
                  usedDisciplineResult.calculatedPoints > 0
                )?_c('span',[_vm._v(" "+_vm._s(usedDisciplineResult.calculatedPoints.toLocaleString())+" ")]):_vm._e()])}),_vm._l((teamResultDiscipline.notusedDisciplineResults),function(notusedDisciplineResult){return _c('span',{key:notusedDisciplineResult.id},[(
                  notusedDisciplineResult.starter.id == starter.id &&
                  notusedDisciplineResult.calculatedPoints > 0
                )?_c('span',{staticClass:"notused"},[_vm._v(" "+_vm._s(notusedDisciplineResult.calculatedPoints.toLocaleString())+" ")]):_vm._e()])})],2):_vm._e()])}),0)})],2)}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }