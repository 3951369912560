
import { Component, Vue, Prop } from "vue-property-decorator";
import DisciplineResult from "../../models/sportevent/DisciplineResult";
import Team from "../../models/sportevent/Team";
import TeamResultDiscipline from "../../models/sportevent/TeamResultDiscipline";
import Discipline from "../../models/sporteventCalculation/Discipline";

@Component({})
export default class ListResultsTeamTableRowComponent extends Vue {
  public loading = true;

  @Prop({ required: true })
  public teamResultDisciplines!: TeamResultDiscipline[];

  @Prop({ required: true })
  public disciplines!: Discipline[];

  @Prop({ required: true })
  public team!: Team[];

  async mounted(): Promise<void> {
    this.loading = false;
  }

  getDisciplineSum(disciplineId: string): string {
    let sum = 0;
    this.teamResultDisciplines.forEach(
      (teamResultDiscipline: TeamResultDiscipline) => {
        if (teamResultDiscipline.discipline.id === disciplineId) {
          teamResultDiscipline.usedDisciplineResults.forEach(
            (disciplineResult: DisciplineResult) => {
              sum = sum + disciplineResult.calculatedPoints;
            }
          );
        }
      }
    );
    if (sum > 0) return sum.toLocaleString();
    else return "";
  }
}
